import React from 'react';

const colors = [
  'emerald',
  'teal',
  'amber',
  'pink',
  'violet',
  'sky',
  'rose',
];

function App() {
  const [theme, setTheme] = React.useState(colors[Math.floor(Math.random()*colors.length)]);

  function changeTheme() {
    do {
      const nextColor = colors[Math.floor(Math.random()*colors.length)];
      if (nextColor !== theme) {
        setTheme(nextColor);
        break;
      }
    } while (true);
  }

  return (
    <>
      <div className="absolute right-0 top-0 z-10">
        <div className="p-2">
          <button
            className="bg-white border-2 border-black h-12 w-12"
            onClick={changeTheme}
          >
            🌈
          </button>
        </div>
      </div>
      <div className={`flex h-full w-full items-center text-9xl tracking-wide font-bold text-${theme}-300`}>
        <div className="flex flex-col w-full h-full">
          <div className={`bg-${theme}-800 h-full flex items-end justify-center border-b border-transparent`}>
            Luke
          </div>
          <div className={`rotate-180 bg-${theme}-700 w-full h-full flex items-end justify-center`}>
            Evers
          </div>
        </div>
      </div>
      <div className="hidden bg-emerald-800 bg-emerald-700 text-emerald-300"></div>
      <div className="hidden bg-teal-800 bg-teal-700 text-teal-300"></div>
      <div className="hidden bg-amber-800 bg-amber-700 text-amber-300"></div>
      <div className="hidden bg-pink-800 bg-pink-700 text-pink-300"></div>
      <div className="hidden bg-violet-800 bg-violet-700 text-violet-300"></div>
      <div className="hidden bg-sky-800 bg-sky-700 text-sky-300"></div>
      <div className="hidden bg-rose-800 bg-rose-700 text-rose-300"></div>
    </>
  );
}

export default App;
